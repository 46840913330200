import React from 'react'
import classnames from 'classnames'

interface SectionOneContentProps {
    className?:string
    rightTextList: string[]
    leftImgUrl:string
}


const SectionOneContent:React.FC<SectionOneContentProps> = ({className,rightTextList,leftImgUrl}) => {
    return <div className={classnames('lg:flex lg:justify-center lg:items-center', className)}>
        <div className='left-content lg:w-[540px]'>
        <img src={leftImgUrl} className="w-full" alt="" />
        </div>
        <div className='right-content pt-[1rem] lg:pt-0 text-center lg:text-left text-[14px] leading-[22px] lg:w-[468px] lg:ml-[46px] text-[#d700cd] lg:leading-[26px] lg:text-[16px]'>
            {rightTextList?.map(item => {
                return <p key={item} dangerouslySetInnerHTML={{__html:item}} />
            })}
        </div>
    </div>
}

export default SectionOneContent