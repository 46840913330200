import React from 'react'
import Logo from './img/logo.png'
import Phone from './img/icon_phone.svg'
import * as styles from './index.module.less'

interface HeaderProps {
  [x: string]: any
}

const Header: React.FC<HeaderProps> = ({ buttonClick }) => {
  return (
    <header className={styles.headerWrapper}>
      <div className={styles.content}>
        <div className={styles.logo} onClick={() => (window.location.href = 'https://www.sensorsdata.cn/')}>
          <img src={Logo} alt="神策数据" />
        </div>
        <div className={styles.rightWrapper}>
          <div className={styles.button} onClick={() => buttonClick?.()}>
            <span>立即申请</span>
          </div>
          <div className={styles.phoneNumber} onClick={() => (window.location.href = 'tel:400-650-9827')}>
            <div className="icon">
              <img src={Phone} alt="" />
            </div>
            <span>400-650-9827</span>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
