import React from 'react'
import classNames from 'classnames'
import * as styles from './index.module.less'

interface SectionTwoCardProps {
    className?:string
    message: string
    rightText?: string
    onClick?:()=>void
}


const SectionTwoCard: React.FC<SectionTwoCardProps> = ({ className, message, rightText = "立即申请",onClick }) => {
    const handleClickCard = () => {
        onClick?.()
    }
    return <div className={classNames(styles.cardItem, 'lg:w-[384px] lg:h-[170px] h-[130px] px-[16px] pt-[4rem] lg:px-[30px] lg:pt-[55px] text-[#fff] lg:text-[16px] text-[14px] lg:leading-[26px] leading-[22px] relative lg:cursor-pointer', className)} onClick={handleClickCard} >
        <div className='right-bottom-text absolute bottom-[2px] lg:bottom-[7px]  right-[44px] lg:right-[56px] lg:leading-[26px] font-medium text-[12px]'>
            <span  >{rightText}</span>
            <img className='ml-[3px] translate-y-[-1px]  w-[12px] h-[12px] ' src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTJweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgMTIgMTIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+57yW57uEIDk8L3RpdGxlPgogICAgPGRlZnM+CiAgICAgICAgPHJlY3QgaWQ9InBhdGgtMSIgeD0iMCIgeT0iMCIgd2lkdGg9IjEyIiBoZWlnaHQ9IjEyIj48L3JlY3Q+CiAgICA8L2RlZnM+CiAgICA8ZyBpZD0i6aG16Z2iLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJwYyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTY3Ni4wMDAwMDAsIC0xNjAxLjAwMDAwMCkiPgogICAgICAgICAgICA8ZyBpZD0i57yW57uELTkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDY3Ni4wMDAwMDAsIDE2MDEuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8bWFzayBpZD0ibWFzay0yIiBmaWxsPSJ3aGl0ZSI+CiAgICAgICAgICAgICAgICAgICAgPHVzZSB4bGluazpocmVmPSIjcGF0aC0xIj48L3VzZT4KICAgICAgICAgICAgICAgIDwvbWFzaz4KICAgICAgICAgICAgICAgIDx1c2UgaWQ9IuiSmeeJiCIgZmlsbC1vcGFjaXR5PSIwIiBmaWxsPSIjRkZGRkZGIiB4bGluazpocmVmPSIjcGF0aC0xIj48L3VzZT4KICAgICAgICAgICAgICAgIDxnIG1hc2s9InVybCgjbWFzay0yKSIgc3Ryb2tlPSIjRkZGRkZGIj4KICAgICAgICAgICAgICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxLjAwMDAwMCwgMS4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICAgICAgPHBvbHlsaW5lIGlkPSLot6/lvoQtMyIgcG9pbnRzPSIwIDEwIDUgNSAwIDAiPjwvcG9seWxpbmU+CiAgICAgICAgICAgICAgICAgICAgICAgIDxwb2x5bGluZSBpZD0i6Lev5b6ELTPlpIfku70iIHBvaW50cz0iNSAxMCAxMCA1IDUgMCI+PC9wb2x5bGluZT4KICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==" alt="立即申请" />
        </div>
        {message}
    </div>
}


export default SectionTwoCard