import React, { useEffect, useMemo, useState } from 'react'
import { useSetState } from 'ahooks'
import formDataSource, { validateByValue } from './formData'
import { fetchSms, fetchSmsVerify } from 'api/user'
import { postGameActivityUserInfo } from 'api/index'
import closeImg from './img/close.svg'
import titleImg from './img/title.svg'
import successImg from './img/success.svg'

import * as styles from './index.module.less'
import classNames from 'classnames'

interface FormProps {
  [x: string]: any
}
interface InputProps {
  id: string
  afterNode?: React.ReactNode
  beforeNode?: React.ReactNode
  placeholder?: string
  state: any
  setState: (any) => void
  errorState: any
  setErrorState: (any) => void
  /** 是否必填，string为提示 */
  required?: string
  /** 校验规则 */
  rules?: { rule: (value: string) => boolean; msg: string }[]
  /** 是否文本与域 */
  isTextarea?: boolean
}

const Input: React.FC<InputProps> = ({
  id,
  afterNode,
  beforeNode,
  placeholder,
  rules = [],
  state,
  setState,
  errorState,
  setErrorState,
  required,
  isTextarea,
}) => {
  // 值
  const value = state[id]
  // 错误信息
  const errorMsg = errorState[id]
  // 是否错误
  const error = !!errorMsg

  const onChange = (value: string) => {
    setState({ [id]: value })
    setErrorState({ [id]: '' })
  }

  const onBlur = () => {
    setErrorState({ [id]: validateByValue({ value: state[id], rules, required }) })
  }

  return (
    <div
      className={`${styles.inputWrapper} ${value ? styles.hasValue : styles.noValue} ${error ? styles.error : ''}`}
      style={
        isTextarea
          ? {
              height: 106,
              padding: '11px 14px',
            }
          : {}
      }
    >
      {afterNode && <div className={styles.afterNode}>{afterNode}</div>}
      {isTextarea ? (
        <textarea
          placeholder={!value && error ? errorMsg : placeholder}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          onBlur={onBlur}
        ></textarea>
      ) : (
        <input
          type="text"
          placeholder={!value && error ? errorMsg : placeholder}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          onBlur={onBlur}
        />
      )}
      {beforeNode && <div className={styles.beforeNode}>{beforeNode}</div>}
      {value && error && <div className={styles.errorMsg}>{errorMsg}</div>}
    </div>
  )
}

const Form: React.FC<FormProps> = ({ show, closeFn }) => {
  // 初始值
  const [state, setState] = useSetState<any>({
    ...formDataSource.reduce((acc, { id }) => ({ ...acc, [id]: '' }), {}),
    privacyPrompt: false,
  })
  // 错误信息
  const [errorState, setErrorState] = useSetState<any>({})

  const [countdown, setCountdown] = useState(0)

  // 是否展示提交成功
  const [showSuccess, setShowSuccess] = useState(false)

  // 获取验证码
  const handleCode = async () => {
    setErrorState({ verification: '' })

    const mobileConfig = formDataSource.find((data) => data.id === 'mobile')
    const mobileMsg = validateByValue({
      value: state.mobile,
      rules: mobileConfig!.rules,
      required: mobileConfig!.required,
    })

    if (!!mobileMsg) {
      setErrorState({ mobile: mobileMsg })
      return
    }

    const res = await fetchSms(state.mobile)

    if (res.data.status === 200) {
      // 在这里开始倒计时
      setCountdown(60)
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(timer)
            return 0
          }
          return prevCountdown - 1
        })
      }, 1000)
    } else {
      setErrorState({ verification: res.desc })
    }
  }

  // 返回true代表校验通过
  const validate = () => {
    const validateRes = {}
    formData.forEach((data) => {
      const { id, required, rules } = data
      validateRes[id] = validateByValue({ value: state[id], rules, required })
    })

    setErrorState({ ...validateRes })

    return !Object.keys(validateRes).some((key) => !!validateRes[key])
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    // 校验表单
    if (!validate()) {
      return
    }

    // 校验协议
    if (!state.privacyPrompt) {
      setErrorState({ privacyPrompt: '请勾选上方同意框' })
      return
    }

    // 校验验证码
    const res = await fetchSmsVerify(state.mobile, state.verification)

    if (res.data.status === 200) {
      // console.log('验证码校验成功')
    } else {
      setErrorState({
        verification: res.data.desc,
      })
      return
    }

    const { mobile, name, email, company, region, gameIntro, url } = state

    try {
      // 发送数据给后端
      const postRes = await postGameActivityUserInfo({
        mobile,
        name,
        email,
        company,
        region,
        gameIntro,
        url,
      })
      setShowSuccess(true)

      setTimeout(() => {
        setShowSuccess(false)
        closeFn()
      }, 2000)
    } catch (error) {
      console.log('網絡異常')
    }
  }

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [show])

  // 添加获取验证码逻辑
  const formData = useMemo(
    () =>
      formDataSource.map((data) =>
        data.id === 'verification'
          ? {
              ...data,
              beforeNode: (
                <div
                  className={classNames(styles.getCode, {
                    [styles.canClick]: countdown === 0,
                    [styles.disClick]: countdown > 0,
                  })}
                  onClick={() => {
                    if (countdown === 0) {
                      handleCode()
                    }
                  }}
                >
                  <span>{countdown > 0 ? `${countdown} 秒后重试` : '获取验证码'}</span>
                </div>
              ),
            }
          : data,
      ),
    [countdown, handleCode],
  )
  // const formData = formDataSource;

  return (
    show && (
      <div className={styles.wrapper}>
        {showSuccess ? (
          <div className={styles.successWrapper}>
            <div className={styles.icon}>
              <img src={successImg} alt="提交成功" />
            </div>
            <span>提交成功</span>
          </div>
        ) : (
          <div className={styles.formWrapper}>
            <div className={styles.header}>
              <div className={styles.close} onClick={closeFn}>
                <img src={closeImg} alt="关闭" />
              </div>
            </div>
            <div className={styles.title}>
              <img src={titleImg} alt="" />
            </div>
            <form autoComplete="off" className={styles.content}>
              {formData.map((data) => {
                return (
                  <Input
                    {...data}
                    key={data.id}
                    state={state}
                    setState={setState}
                    errorState={errorState}
                    setErrorState={setErrorState}
                  />
                )
              })}
              <div className={styles.privacyPrompt}>
                <div
                  className={classNames(styles.checkbox, {
                    [styles.checked]: state.privacyPrompt,
                  })}
                  onClick={() => {
                    setErrorState({ privacyPrompt: '' })
                    setState({ privacyPrompt: !state.privacyPrompt })
                  }}
                ></div>
                <span>
                  <a href="/compliance/privacy.html" target="_blank">
                    《隐私政策》
                  </a>
                  ｜
                  <a href="/compliance/service.html" target="_blank">
                    《用户服务协议》
                  </a>
                </span>
                {errorState.privacyPrompt && <div className={styles.error}>{errorState.privacyPrompt}</div>}
              </div>

              <button type="submit" onClick={handleSubmit} className={styles.submit}>
                提交
              </button>
            </form>
          </div>
        )}
      </div>
    )
  )
}

export default Form
