// extracted by mini-css-extract-plugin
export var annotation = "index-module--annotation--RHNb0";
export var canBuy = "index-module--canBuy--sdRdA";
export var gartnerWrapper = "index-module--gartnerWrapper--m3K4s";
export var icon = "index-module--icon--bg+YG";
export var itemContent = "index-module--itemContent--G9NZa";
export var itemHeader = "index-module--itemHeader--yLrGJ";
export var line = "index-module--line--tWrh9";
export var price = "index-module--price--7p+Tu";
export var priceTable = "index-module--priceTable--9R96s";
export var root = "index-module--root--c10nS";
export var row = "index-module--row--7JVh6";
export var section3Btn = "index-module--section3Btn--IEkNJ";
export var section3Item = "index-module--section3Item--Rnu1f";
export var sectionThree = "index-module--sectionThree--ab1G2";
export var sectionWrapper = "index-module--sectionWrapper--Oe-NW";
export var swiperItem = "index-module--swiperItem--VaTeC";
export var tip = "index-module--tip--dKNra";