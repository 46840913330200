import React, { useState } from 'react'
import Layout from 'layouts/zh'
import { GartnerWidget, Seo, SwiperCom } from 'components/index'
import {
  Header,
  Footer,
  Banner,
  Section,
  Form,
  SectionOneContent,
  SectionTwoCard,
  SectionThreeCard,
} from 'components/GameActivityCom/index'
import * as styles from './index.module.less'
import classnames from 'classnames'
import useIsWindowWidthSmaller from 'hooks/useWindowWidth'

interface researchInstituteProps {
  [x: string]: any
}

const cardContentList = [
  '针对数据产品价格高、数据平台研发代价大等问题，神策游戏云将会对游戏小研发团队开启创意护航的扶持计划！',
  '创意护航计划将会提供功能完整、灵活可用、接入简单、价格合理的全套数据产品——神策 mini 游戏云，来助力每一份梦想。',
  '优质项目成员更将在创意护航计划之上再获得最高 5 折的额外折扣券！',
]
const section3List = [
  {
    title: '提交申请',
    desc: '请您认真填写并提交申请表单',
    img_url: require('assets/images/gameActivity/section3_01.svg').default,
  },
  {
    title: '资格审核',
    desc: '我们将尽快与您电话联系',
    img_url: require('assets/images/gameActivity/section3_02.svg').default,
  },
  {
    title: '开通产品',
    desc: '入选的企业可优惠使用神策 mini 游戏云',
    img_url: require('assets/images/gameActivity/section3_03.svg').default,
  },
]

const tableData = [
  { method: '单机私部', number1: 2000, number2: 1000, days: 6, price: '7,000', note: '自助', canBuy: 1 },
  { method: '单机私部', number1: 5000, number2: 2000, days: 6, price: '12,000', note: '自助', canBuy: 1 },
  { method: '单机私部', number1: 10000, number2: 4000, days: 6, price: '18,000', note: '自助', canBuy: 1 },
]

const TableComponent = () => {
  return (
    <table className={styles.priceTable}>
      <thead>
        <tr>
          <th>部署方式</th>
          <th>事件量 (万条)</th>
          <th>
            预估日活
            <div className={styles.annotation}> 按日均每人事件量 150 条估算</div>
          </th>
          <th>时长 (月)</th>
          <th>标价</th>
          <th>服务等级</th>
          <th>可购买次数</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map((item) => (
          <tr key={item.method + item.number1 + item.price}>
            <td>{item.method}</td>
            <td>{item.number1}</td>
            <td>{item.number2}</td>
            <td>{item.days}</td>
            <td>¥{item.price}</td>
            <td>{item.note}</td>
            <td>{item.canBuy}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const ItemCom = ({ price, canBuy, method, number1, number2, days, note }) => (
  <div className={styles.swiperItem}>
    <div className={styles.itemHeader}>
      <div className={styles.row}>
        <div className={styles.price}>{price}</div>
        <div className={styles.canBuy}>
          <div>限购 {canBuy} 次</div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.tip}>预估日活按日均每人事件量 150 条估算</div>
      </div>
    </div>
    <div className={styles.line}></div>
    <div className={styles.itemContent}>
      {[
        `部署方式：${method}`,
        `事件量 (万条)：${number1}`,
        `预估日活：${number2}`,
        `时长 (月)：${days}`,
        `服务等级：${note}`,
        `可购买次数：${canBuy}`,
      ].map((str) => (
        <div className={styles.row}>
          <div className={styles.icon}></div>
          <div className={styles.text}>{str}</div>
        </div>
      ))}
    </div>
  </div>
)

const SwiperComponent = () => {
  return (
    <SwiperCom
      nodes={tableData.map((data) => (
        <ItemCom {...data} />
      ))}
      swiperType={{ type: 'double', mbWidth: '20rem' }}
      indicatorType="none"
    />
  )
}

const GameActivity: React.FC<researchInstituteProps> = (props) => {
  const { data } = props
  // const { researchInstituteYaml } = data
  // const { part1, part2, part3, part4, part5, part6, part7 } = researchInstituteYaml
  const [formShow, setFormShow] = useState(false)
  const isMb = useIsWindowWidthSmaller()

  return (
    <Layout
      header={
        <Header
          buttonClick={() => {
            setFormShow(true)
          }}
        />
      }
      showBackTop={false}
      showFloat={false}
      showPhoneCall={false}
      hideFooter={true}
      {...props}
    >
      <Seo
        title="神策游戏云-创意护航计划"
        description="神策游戏云发起创意护航计划，致力于为每个游戏提供小成本、高可用性的数据中台——神策 mini 游戏云，助力游戏研发加速！"
        keywords="神策游戏云,创意护航计划,小研发扶持,立即申请"
      />
      <div className={styles.root}>
        <Banner
          buttonClick={() => {
            setFormShow(true)
          }}
        />
        <main className={styles.sectionWrapper}>
          <Section
            title="谁能参与创意护航计划"
            title_img={require('assets/images/gameActivity/section_title_01.png').default}
            title_img_mb={require('assets/images/gameActivity/section_title_01_mb.png').default}
          >
            <SectionOneContent
              className="lg:pt-[40px] pt-[2rem]"
              leftImgUrl={require('assets/images/gameActivity/section_01.png').default}
              rightTextList={[
                "<span class='text-[#04D4F4]'>如果你是一个</span>游戏研发者",
                "<span class='text-[#04D4F4]'>如果你相信</span>迭代<span class='text-[#04d4f4]'>是帮助你的游戏脱颖而出的最佳手段</span>",
                "<span class='text-[#04D4F4]'>如果你的游戏正在或者即将</span>内测，<span class='text-[#04D4F4]'>急需针对几百用户进行深入</span>灵活的行为分析，<span class='text-[#04D4F4]'>而又苦于市面上</span>商业解决方案<span class='text-[#04D4F4]'>的高昂财务成本和开源方案的高昂人力成本，那么我们来聊一聊</span>",
              ]}
            />
          </Section>
          <Section
            title="创意护航计划能够提供什么"
            title_img={require('assets/images/gameActivity/section_title_02.png').default}
            title_img_mb={require('assets/images/gameActivity/section_title_02_mb.png').default}
          >
            <div className="lg:flex lg:justify-center lg:mt-[40px] mt-[20px]">
              {cardContentList?.map((item) => {
                return (
                  <SectionTwoCard
                    onClick={() => setFormShow(true)}
                    className="lg:mx-[10px] mb-[20px] lg:mb-0"
                    key={item}
                    message={item}
                  />
                )
              })}
            </div>
          </Section>
          <Section
            title="神策 mini 游戏云的产品定价"
            title_img={require('assets/images/gameActivity/section_title_update_01.png').default}
            title_img_mb={require('assets/images/gameActivity/section_title_update_01_mb.png').default}
          >
            <div className="lg:flex lg:justify-center lg:mt-[40px] mt-[20px]">
              {isMb ? <SwiperComponent></SwiperComponent> : <TableComponent />}
            </div>
          </Section>
          <Section
            title="申请流程"
            title_img={require('assets/images/gameActivity/section_title_03.png').default}
            title_img_mb={require('assets/images/gameActivity/section_title_03_mb.png').default}
          >
            <div className={classnames(styles.sectionThree, 'lg:mt-[80px] mt-[20px]')}>
              <div className="section-three-wrapper w-full lg:w-[1200px] lg:mx-auto lg:flex justify-evenly py-[1rem] lg:py-0">
                {section3List?.map((item) => {
                  return (
                    <SectionThreeCard
                      className={classnames(styles.section3Item, ' lg:w-[300px] ')}
                      key={item.title}
                      {...item}
                    />
                  )
                })}
              </div>
              <span className="left-arrow" />
              <span className="right-arrow" />
            </div>
            <div className="text-center lg:mt-[60px] mt-[30px] ">
              <button
                className={classnames(
                  styles.section3Btn,
                  'section3-btn w-[138px] h-[37px] text-[12px] text-[#fff]  leading-[37px]',
                )}
                onClick={() => setFormShow(true)}
              >
                立即申请
              </button>
            </div>
          </Section>
          <Footer className="lg:mt-[100px] mt-[2rem]" />
        </main>

        <div className={styles.gartnerWrapper}>
          <GartnerWidget />
        </div>
        <Form show={formShow} closeFn={() => setFormShow(false)}></Form>
      </div>
    </Layout>
  )
}

// export const query = graphql`

// `

export default GameActivity
