import React from 'react'
import classnames from 'classnames'



interface SectionThreeCardProps {
    className?: string
    img_url: string
    title: string
    desc:string
}

const SectionThreeCard:React.FC<SectionThreeCardProps> = ({className,title,desc,img_url}) => {
    return <ul className={classnames(className,'text-center flex lg:flex-col justify-start lg:justify-center items-center')}>
        <li className='lg:w-auto w-[48px] text-left lg:text-center'>
            <img className='lg:h-[45px] h-[30px]' src={img_url} alt={title} />
        </li>
        <li className='lg:my-[24px] relative z-[2] ml-[12px] mr-[24px] lg:mx-0'>
            <span className='section3-circle inline-block lg:w-[20px] lg:h-[20px] h-[12px] w-[12px]'>
                <img className='w-full h-full' src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+5qSt5ZyG5b2i5aSH5Lu9IDI8L3RpdGxlPgogICAgPGRlZnM+CiAgICAgICAgPGxpbmVhckdyYWRpZW50IHgxPSI1MCUiIHkxPSIwJSIgeDI9IjUwJSIgeTI9IjEwMCUiIGlkPSJsaW5lYXJHcmFkaWVudC0xIj4KICAgICAgICAgICAgPHN0b3Agc3RvcC1jb2xvcj0iI0Y0MTVDNCIgb2Zmc2V0PSIwJSI+PC9zdG9wPgogICAgICAgICAgICA8c3RvcCBzdG9wLWNvbG9yPSIjMDJERUZCIiBvZmZzZXQ9IjEwMCUiPjwvc3RvcD4KICAgICAgICA8L2xpbmVhckdyYWRpZW50PgogICAgPC9kZWZzPgogICAgPGcgaWQ9Iumhtemdoi0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0icGMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMzUxLjAwMDAwMCwgLTE5NDMuMDAwMDAwKSIgZmlsbD0iIzI4MTA1OSIgc3Ryb2tlPSJ1cmwoI2xpbmVhckdyYWRpZW50LTEpIj4KICAgICAgICAgICAgPGNpcmNsZSBpZD0i5qSt5ZyG5b2i5aSH5Lu9LTIiIGN4PSIxMzYxIiBjeT0iMTk1MyIgcj0iOS41Ij48L2NpcmNsZT4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==" alt="" />
            </span>
        </li>
        <li className='text-left lg:text-center'>
            <p className='lg:text-[24px] text-[18px] leading-[26px] font-medium text-[#fff]'>{title}</p>
            <p className='leading-[26px] text-[14px] text-[#fff] lg:text-[16px]'>{desc}</p>
        </li>
    </ul>
}


export default SectionThreeCard
