import React from 'react'
import * as styles from './index.module.less'

// 校验值，返回错误信息，无错误返回‘’
export const validateByValue = ({ value, rules, required }) => {
  let error: any = null
  if (rules?.length > 0) {
    for (let ruleItem of rules) {
      const { rule: validate, msg } = ruleItem
      const res = validate(value)
      error = res ? '' : msg
      if (!res) {
        break
      }
    }
  }
  if (required && (value == null || value === '')) {
    error = required
  }
  return error
}

export default [
  {
    id: 'mobile',
    placeholder: '请输入手机号',
    afterNode: <span className={styles.inputTip}>中国大陆（86）</span>,
    required: '* 请输入手机号',
    rules: [{ rule: (value) => /^1[3-9]\d{9}$/.test(value), msg: '手机号格式不正确' }],
  },
  {
    id: 'verification',
    placeholder: '验证码',
  },
  {
    id: 'name',
    placeholder: '姓名',
    required: '* 请输入姓名',
  },
  {
    id: 'email',
    placeholder: '企业邮箱',
    required: '* 请输入邮箱',
    rules: [{ rule: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value), msg: '邮箱格式不正确' }],
  },
  {
    id: 'company',
    placeholder: '工作室/公司名称',
    required: '* 请输入工作室/公司名称',
  },
  {
    id: 'region',
    placeholder: '地区（市）',
    required: '* 请输入地区（市）',
  },
  {
    id: 'gameIntro',
    placeholder: '游戏介绍（重要！包括不限于游戏名称、玩法、亮点等等）',
    required: '* 请输入游戏介绍',
    isTextarea: true,
  },
  {
    id: 'url',
    placeholder: '游戏官网（选填）',
  },
]
