import useIsWindowWidthSmaller from 'hooks/useWindowWidth'
import React from 'react'
import BannerImg from './img/banner.png'
import BannerMbImg from './img/bannerMb.png'
import whiteRightArrow from './img/whiteRightArrow.svg'
import * as styles from './index.module.less'

interface BannerProps {
  [x: string]: any
}

const Banner: React.FC<BannerProps> = ({ buttonClick }) => {
  const isMb = useIsWindowWidthSmaller()

  return (
    <figure className={styles.bannerWrapper}>
      <img src={isMb ? BannerMbImg : BannerImg} alt="Banner Image" />
      <figcaption>
        创意可以被工业化辅助。该计划由神策数据发起，致力于为每个游戏提供小成本、高可用性的数据中台——神策 mini 游戏云，助力游戏研发加速！
      </figcaption>
      <div className={styles.button}>
        <div className={styles.left}>
          <div className={styles.icon}></div>
          <span>神策 mini 游戏云</span>
        </div>
        <div className={styles.right} onClick={buttonClick}>
          <span>立即申请</span>
          <div className={styles.icon}>
            <img src={whiteRightArrow} alt="" />
          </div>
        </div>
      </div>
    </figure>
  )
}

export default Banner
